document.addEventListener('DOMContentLoaded', () => {
  // トグル
  new Vue({
    el: '.header',
    data: {
      // navi: true,
      navi: false,
    },
    methods: {
      toggle () {
        this.navi = !this.navi
      }
    }
  })
})
